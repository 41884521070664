function ComingSoonEthereum(){
    return(
        <div className="coming-soon-wrapper home-wrapper">
            <div className="coming-soon-content">
                <h1>Coming Soon <span className="dot">.</span></h1>
                <p>It's going to be amazing!</p>
            </div>
        </div>
    )
}


export default ComingSoonEthereum;